<template>
  <div>
    <!-- Blogs  -->
    <div class="mt-5">
      <div class="container">
        <div class="row mb-2">
          <div class="col-12">
            <h1 class="f-bold cl-primary text-center mt-5">News / Blogs</h1>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12"  v-if="data.length == 0">
            <p class="cl-primary font-weight-bold">Blog Not Found</p>
          </div>
          <div class="col-md-12 mt-3" v-for="(blog, p) in data" :key="p">
            <div class="card">
              <div class="row no-gutters">
                <div class="col-auto p-3">
                  <img
                    :src="blog.file_endpoint"
                    class="img-fluid b-radius"
                    alt=""
                  />
                </div>
                <div class="col">
                  <div class="card-block p-4 text-left">
                    <h4 class="card-title f-bold cl-primary">
                      {{ blog.title }}
                    </h4>
                    <p class="card-text cl-secondary">
                      {{ blog.body }}
                    </p>
                    <router-link :to="'/detail-blog/' + blog.slug"
                      >Read more</router-link
                    >
                    <!-- <a href="#" class="btn btn-success px-4">Read More</a> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-3 mb-5">
          <div class="col-md-12 text-center">
            <router-link to="/blogs" class="btn btn-success py-2 px-5 b-radius" v-if="data.length !== 0"
              >More News</router-link
            >
            <!-- <button class="btn btn-success px-5" class="">More News</button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BlogCustomer",
  data() {
    return {
      loading: true,
      data: [],
    };
  },

  mounted() {
    this.getAllBlogs();
  },

  methods: {
    getAllBlogs() {
      this.loading = true;
      const endpoint = "blog";
      this.$api.get(endpoint, (status, data, message) => {
        if (status === 200) {
          this.loading = false;
          this.data = data.blog;
          console.log(this.data);
        } else {
          this.loading = false;
          console.log(message);
        }
      });
    },
  },
};
</script>
