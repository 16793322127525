<template>
  <div>
    <!-- promo  -->
    <div class="" style="background-color: #45aaf2">
      <div class="container">
        <div class="row mb-2">
          <div class="col-12">
            <div class="d-flex justify-content-between">
              <h1 class="f-bold cl-white text-start mt-5">Promotion</h1>
              <h6 class="f-bold cl-white text-start mt-5 align-self-center">
                <router-link to="/promotions" class="cl-white" v-if="data.length !== 0"
                  >View All</router-link
                >
              </h6>
            </div>
          </div>
        </div>

        <p v-if="data.length == 0" class="py-5 cl-white font-weight-bold"> Promotion Not Found!</p>
        <VueSlickCarousel v-bind="settings" class="row pb-5" v-if="!loading && data.length !== 0 ">
          <div v-for="(promotion, i) in data" :key="i">
            <div
              class="card p-3 cursor-pointer"
              @click.prevent="toDetail(promotion.slug)"
            >
              <img
                class="card-img-top-promo"
                :src="promotion.file_endpoint"
                style="height: 170px; object-fit: cover"
                alt="Card image cap"
              />
              <div class="card-body text-left">
                <h4 class="f-bold cl-white">
                  {{ promotion.title }}
                </h4>

                <p class="card-text cl-white">
                  {{ promotion.description }}
                </p>
                <div class="d-flex justify-content-between text-white">
                  <p
                    style="font-size: 13px"
                    class="badge badge-success px-3 py-2 b-radius"
                  >
                    Hingga
                    {{ moment(promotion.expired_at).format("DD-MM-YYYY") }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </VueSlickCarousel>
      </div>
    </div>
  </div>
</template>
<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
export default {
  data() {
    return {
      loading: true,
      data: [],
      settings: {
        dots: false,
        focusOnSelect: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        touchThreshold: 5,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
    };
  },
  name: "PromoCustomer",
  components: { VueSlickCarousel },
  mounted() {
    this.getAllPromotion();
  },

  methods: {
    getAllPromotion() {
      this.loading = true;
      const endpoint = "promotion";
      this.$api.get(endpoint, (status, data, message) => {
        if (status === 200) {
          this.loading = false;
          this.data = data.promotion;
          console.log("------");
          console.log(this.data);
        } else {
          this.loading = false;
          console.log(message);
        }
      });
    },

    toDetail(slug) {
      this.$router.push("/promotions/" + slug);
    },
  },
};
</script>
