<template>
  <div>
    <banner-customer></banner-customer>
    <about-customer></about-customer>
    <promo-customer></promo-customer>
    <blog-customer></blog-customer>
  </div>
</template>
<script>
import BannerCustomer from "../../components/customers/BannerCustomer.vue";
import AboutCustomer from "../../components/customers/AboutCustomer.vue";
import PromoCustomer from "../../components/customers/PromoCustomer.vue";
import BlogCustomer from "../../components/customers/BlogCustomer.vue";

export default {
  components: { BannerCustomer, AboutCustomer, PromoCustomer, BlogCustomer },
  name: "Homepage",
};
</script>
