<template>
  <div class="">
    <VueSlickCarousel v-bind="settings" v-if="!loading">
      <div v-for="(banner, i) in data" :key="i">
        <div class="card" style="border-radius: none;">
          <img
            class="card-img-top-banner"
            :src="banner.file_endpoint"
            alt="Card image cap"
            style="height: 600px !important; border-radius: none !important;"
          />
          <div class="card-body position-absolute bot-pos">
            <div class="flex justify-content-center">
              <div class="card-title">
                <h3 class="font-weight-bold text-white">{{ banner.title }}</h3>
                <p class="text-white">{{ banner.subtitle }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </VueSlickCarousel>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
export default {
  name: "Banner",
  components: { VueSlickCarousel },
  // components: { LoaderComponent },
  data() {
    return {
      loading: true,
      data: [],
      settings: {
        dots: true,
        dotsClass: "slick-dots custom-dot-class",
        edgeFriction: 0.35,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: false,
        nextArrow: false,
        arrows: false,
      },
    };
  },

  mounted() {
    this.getAllBanners();
  },

  methods: {
    getAllBanners() {
      this.loading = true;
      const endpoint = "banner";
      this.$api.get(endpoint, (status, data, message) => {
        if (status === 200) {
          this.loading = false;
          this.data = data.banner;
          console.log(this.data);
        } else {
          this.loading = false;
          console.log(message);
        }
      });
    },
  },
};
</script>
